/**
 * Taken from Lakeside Cabin repository.
 * These values are used to handle remote navigation.
 */
export class KeyCodes {
    static Home = 36;
    static ArrowLeft = 37;
    static ArrowUp = 38;
    static ArrowRight = 39;
    static ArrowDown = 40;

    static w = 87;
    static a = 65;
    static s = 83;
    static d = 68;
    static Lefts = [KeyCodes.ArrowLeft, KeyCodes.a, "ArrowLeft"];
    static Ups = [KeyCodes.ArrowUp, KeyCodes.w, "ArrowUp"];
    static Rights = [KeyCodes.ArrowRight, KeyCodes.d, "ArrowRight"];
    static Downs = [KeyCodes.ArrowDown, KeyCodes.s, "ArrowDown"];

    static RightsDowns = KeyCodes.Rights.concat(KeyCodes.Downs);
    static LeftsUps = KeyCodes.Lefts.concat(KeyCodes.Ups);

    static Enter = 13;
    static Backspace = 461;
    static Escape = 27;
    static plus = 187;
    static samsung_tv_back = 10009;
    static sony_tv_back = 48;

    static Backspace_mac = 8;
    static Backspaces = [
        KeyCodes.Backspace_mac,
        KeyCodes.Backspace,
        KeyCodes.Escape,
        KeyCodes.plus,
        KeyCodes.samsung_tv_back,
        KeyCodes.sony_tv_back,
    ];
}
