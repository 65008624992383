/**
 * CustomChessboard.js
 * Handles the chessboard logic for the chess app.
 *
 * @author Braden Zingler
 * Last modified 02/13/2025
 */
import "./CustomChessboard.css";
import { CustomBoardConstants } from "../../../utils/constants";
import {
    calcBoardPadding,
    calcBoardWidth,
    findKingFromFenString,
    calculateIsPromotion,
    getRightSquare,
    getLeftSquare,
    getUpSquare,
    getDownSquare,
} from "../../../utils/utils";
import { useCustomPieces } from "../CustomPieces";
import usePlayAudio from "../../MoveAudio";
import { useGame } from "../GameContext";
import { sendMultiplayerGameUpdate } from "../../../utils/multiplayer/handlers";
import { aiMove } from "js-chess-engine";
import { useEffect, useState, useRef } from "react";
import { Chessboard, ChessboardDnDProvider } from "react-chessboard";
import { KeyCodes } from "../../../utils/keys/KeyCodes";
import { restartGame, handleHome, forfeitGame } from "../../../utils/game/game";


/**
 * The CustomChessboard component is a wrapper around the react-chessboard component.
 * It provides additional functionality such as
 * highlighting available moves, drag-and-drop, and promotion.
 * @returns the CustomChessboard component
 */
export default function CustomChessboard() {


    const [availableMoves, setAvailableMoves] = useState([]); // The available moves for the clicked piece.
    const [clickedPiece, setClickedPiece] = useState(null); // The piece that was clicked
    const [hoveredSquare, setHoveredSquare] = useState(null); // The square that is being hovered over
    const [pendingMove, setPendingMove] = useState(null); // Stores the pending move details
    const [isRemoteInMenuButtons, setIsRemoteInMenuButtons] = useState(false); // Track if remote is in menu buttons // Doesnt work
    const [activeMenuButton, setActiveMenuButton] = useState(-1); // Track the active menu button
    const playerTurn = useRef(true); // Track if players turn
    const playAudio = usePlayAudio();



    const {
        gameState,
        setGameState,
        promotion,
        setPromotion,
        multiplayerState,
        setMultiplayerState,
        remoteNavigation,
        setRemoteNavigation,
    } = useGame();




    /**
     * Update playerTurn ref when gameState changes and make AI move appropriately.
     * This only runs when the AI is playing.
     */
    useEffect(() => {
        if (
            gameState.game.turn() === "b" &&
            playerTurn.current &&
            !gameState.game.isGameOver()
        ) {
            // Opponents turn
            playerTurn.current = gameState.game.turn() === "w"; // Mark it's opponents turn.
            if (gameState.isAIGame) {
                makeAImove();
            }
        } else if (gameState.game.turn() === "w") {
            // Players turn
            playerTurn.current = true;
        }
    }, [gameState.fen]);




    /**
     * Handle promotion and game movement when promotionType changes.
     * Pending moves are stored in state to complete the move after the promotion type is selected.
     * We need to wait for the promotion type to be selected before completing the move.
     */
    useEffect(() => {
        if (promotion.type && pendingMove) {
            handleMove(pendingMove.from, pendingMove.to, promotion.type);
            setPromotion({ open: false, type: null });
            setPendingMove(null);
        }
    }, [promotion.type, pendingMove, setPromotion, setPendingMove]);





    /**
     * Determines if the player is allowed to make a move.
     * @returns true if the player is allowed to make a move, false otherwise.
     */
    function allowMove() {

        let pawnPromotionMenuNotOpen = !promotion.open;
        let correctPlayerTurn = (gameState.game.turn() === multiplayerState.playerColor);
        let playerIsNotHost = !multiplayerState.isHost;
        let noForfeitOccured = !multiplayerState.forfeit.forfeit;
        let moveIsAllowed = pawnPromotionMenuNotOpen && correctPlayerTurn && playerIsNotHost && noForfeitOccured

        if (moveIsAllowed) {
            return true;
        } else {
            return false
        }
    }



    function onPieceDragEnd(){
        setClickedPiece(null);
        setAvailableMoves([]);
    }




    //Refactor  //Also- when using the keyboard and pressing enter on a piece, the piece zooms out and gets bigger but that doesn't work when clicking a piece so also good to fix that
    /**
     * Handles the click event on a square.
     * Highlights available moves for the clicked piece.
     * @param {*} square the square that was clicked.
     */  //Definitely refactor...
    function onSquareClick(square) {

        let prevClickedPiece = clickedPiece
        let currClickedPiece = square

        //If this clicked square is the same as the last clicked square, or move is not allowed, deselect and return
        if (prevClickedPiece === currClickedPiece || (allowMove() === false)){
            setRemoteNavigation((prev) => ({...prev, remoteClickSquare: null,}));
            setRemoteNavigation((prev) => ({...prev, remoteOverSquare: null,}));
            setAvailableMoves([])
            setClickedPiece(null)
            return;
        }

        // If clicking on an available move square, make the move
        if (clickedPiece && availableMoves.includes(square)) {
            const isPromotionMove = handlePromotion(clickedPiece, square);

            if (!isPromotionMove) {
                // If not a promotion move, it is just a normal move.
                handleMove(clickedPiece, square);
                setRemoteNavigation((prev) => ({
                    ...prev,
                    remoteClickSquare: null,
                }));
            }
        } else if (
            gameState.game.get(square) &&
            gameState.game.get(square).color === gameState.game.turn()
        ) {
            // If a new piece is clicked, highlight its available moves

            // Not the players piece.
            if (
                gameState.game.get(square).color !==
                multiplayerState.playerColor
            )
                return;

            const moves = gameState.game.moves({ square, verbose: true });
            const available = moves.map((move) => move.to);
            setClickedPiece(square);
            setAvailableMoves(available);
        }
    }



    /**
     * Handles the drop event (drag-and-drop).
     * Shows the available moves for the piece being dragged.
     * @param {String} sourceSquare the source square of the piece.
     * @param {String} targetSquare the target square of the piece.
     */
    function onDrop(sourceSquare, targetSquare) {

        let isMoveAllowed = allowMove();

        if (isMoveAllowed === false){ 
            return false;
        }

        if (
            sourceSquare === targetSquare ||
            promotion.open ||
            gameState.game.turn() !== multiplayerState.playerColor
        ) {
            // Did not move or promotion dialog is open.
            return false;
        }
        const isPromotionMove = handlePromotion(sourceSquare, targetSquare);
        if (!isPromotionMove) {
            handleMove(sourceSquare, targetSquare);
        }
        return true;
    }



    //Refactor
    /**
     * Moves a piece on the board.
     * Handles the move and updates the game position.
     *
     * @param {String} sourceSquare the source square of the piece.
     * @param {String} targetSquare the target square of the piece.
     * @param {String} promotion the promotion type (defaulted to undefined).
     * @returns {boolean} true if the move was successful, false otherwise.
     */
    function handleMove(sourceSquare, targetSquare, promotion = undefined) {
        try {
            const move = gameState.game.move({
                from: sourceSquare,
                to: targetSquare,
                promotion: promotion,
            });
            if (move !== null) {
                playAudio();
                setGameState((prev) => ({
                    ...prev,
                    fen: gameState.game.fen(),
                    lastMove: { from: sourceSquare, to: targetSquare },
                }));
                // Send the game state to the opponent, to update both boards.
                if (!gameState.isAIGame) {
                    sendMultiplayerGameUpdate(
                        multiplayerState.ws,
                        gameState.id,
                        gameState.game.fen(),
                    );
                }
                setClickedPiece(null);
                setAvailableMoves([]);
                return true;
            }
        } catch (error) {
            // Invalid move
            console.log(error);
            return false;
        }
    }



    /**
     * Makes a move for the AI.
     * If the AI has a promotion, it defaults to a queen promotion since it can't make a choice.
     */
    async function makeAImove() {
        // Delay AI move for better UX
        await new Promise((r) =>
            setTimeout(r, CustomBoardConstants.AI_RESPONSE_DELAY),
        );
        const move = aiMove(gameState.game.fen(), gameState.aiDifficulty);
        const [from, to] = Object.entries(move)[0];
        handleMove(
            from.toLowerCase(),
            to.toLowerCase(),
            CustomBoardConstants.AI_PROMOTION_TYPE,
        );
    }


    


    /**
     * Handles the drag event.
     * Shows the available moves for the piece being dragged.
     * @param {String} sourceSquare the source square of the piece.
     */
    function onPieceDragBegin(piece, square) {

        let isMoveAllowed = allowMove();
        if (isMoveAllowed === false){
            return;
        }

        const moves = gameState.game.moves({
            square,
            verbose: true,
        });
        const available = moves.map((move) => move.to);

        setClickedPiece(square);
        setAvailableMoves(available);
    }




    /**
     * Called whenever a user presses a key on the keyboard
     * @param {KeyboardEvent} event - The keyboard event object.
     */
    function handleKeyDown(event) {
        event.preventDefault();

        if (promotion.open) return; //Investigate if keyboard is working properly in promotion menu ***NICK***


        let pressedKeyCode = event.code; //Example: On keyboard, eventCode for enter button = "Enter"

        let leftArrowKeyCodes = KeyCodes.Lefts;
        let rightArrowKeyCodes = KeyCodes.Rights;
        let upArrowKeyCodes = KeyCodes.Ups;
        let downArrowKeyCodes = KeyCodes.Downs;
        let enterKeyCodes = [KeyCodes.Enter, "Enter"];
        let escapeKeyCodes = [KeyCodes.Escape, "Escape"];

        let pressedKeyIsRight = rightArrowKeyCodes.includes(pressedKeyCode);
        let pressedKeyIsLeft = leftArrowKeyCodes.includes(pressedKeyCode);
        let pressedKeyIsUp = upArrowKeyCodes.includes(pressedKeyCode);
        let pressedKeyIsDown = downArrowKeyCodes.includes(pressedKeyCode);
        let pressedKeyIsEnter = enterKeyCodes.includes(pressedKeyCode);
        let pressedKeyIsEscape = escapeKeyCodes.includes(pressedKeyCode);

        let menuDiv = document.getElementsByClassName("menu").item(0);
        let menuButtons = menuDiv.childNodes;



        //Handle escape press (Quits to home screen)
        if (pressedKeyIsEscape) {
            handleHome(setGameState, setMultiplayerState);
            return;
        }



        //Handle going from menu to board
        if(isRemoteInMenuButtons && pressedKeyIsDown){

            setRemoteNavigation((prev) => ({...prev,remoteOverSquare: "h8",}));
            setIsRemoteInMenuButtons(false);

    
            //Singleplayer 3 button menu
            if (gameState.isAIGame){
                let difficultyHTMLElement = menuButtons[0];
                let difficultySelectElement = difficultyHTMLElement.childNodes[0];
                
                difficultySelectElement.classList.remove("focused-button");

                let restartButton = menuButtons[1];
                restartButton?.classList?.remove("focused-button");

                let homeButton = menuButtons[2];
                homeButton?.classList?.remove("focused-button");
            }


            //Multiplayer 1 button menus
            else {
                let hostStatus = multiplayerState.isHost;


                //Host Menu
                if (hostStatus === true){
                    let homeButton = menuButtons[0];
                    homeButton?.classList?.remove("focused-button");
                }


                //Player Menu
                else {
                    let forfeitButton = menuButtons[0];
                    forfeitButton?.classList?.remove("focused-button");
                }

            }

            return;
        }




        //If current remote square position is on board, get it's coordinates ie "a8" 
        if(!isRemoteInMenuButtons){
            let prevSquare = remoteNavigation.remoteOverSquare;
            var prevSquareRow = prevSquare[0];
            var prevSquareColumn = prevSquare[1];
        }

        //Handle going from board to menu (from row 8 or column h)
        if ((prevSquareRow === 'h' && pressedKeyIsRight) || (prevSquareColumn == 8 && pressedKeyIsUp)){

            setActiveMenuButton(0);
            setIsRemoteInMenuButtons(true);

            //Singleplayer case
            if (gameState.isAIGame){
                let difficultyHTMLElement = menuButtons[0];
                let difficultySelectElement = difficultyHTMLElement.childNodes[0];
                difficultySelectElement?.classList?.add("focused-button");
            }

            //Multiplayer cases
            else {

                let hostStatus = multiplayerState.isHost;

                //Host Menu
                if (hostStatus === true){
                    let homeButton = menuButtons[0];
                    homeButton?.classList?.add("focused-button");
                }

                //Player Menu
                else {
                    let forfeitButton = menuButtons[0];
                    forfeitButton?.classList?.add("focused-button");
                }
            }
            return;
        }




        //Handle going between menu buttons
        if (isRemoteInMenuButtons && !pressedKeyIsDown && !pressedKeyIsEnter && !pressedKeyIsUp) {

            //Singleplayer menu cases
            if (gameState.isAIGame) {

                //Case for going from difficulty button to restart button
                if (activeMenuButton === 0 && pressedKeyIsRight){

                    let difficultyHTMLElement = menuButtons[0];
                    let difficultySelectElement = difficultyHTMLElement.childNodes[0];
                    difficultySelectElement?.classList?.remove("focused-button"); //remove highlight from difficulty button
                    menuButtons[1]?.classList?.add("focused-button"); //highlighting restart button
                    setActiveMenuButton(1);
                }

                //Case for going from restart button to difficulty button
                else if (activeMenuButton === 1 && pressedKeyIsLeft){

                    menuButtons[1]?.classList?.remove("focused-button"); //remove highlight from restart button
                    let difficultyHTMLElement = menuButtons[0];
                    let difficultySelectElement = difficultyHTMLElement.childNodes[0];
                    difficultySelectElement?.classList?.add("focused-button"); //add highlight from difficulty button
                    difficultySelectElement.focus();
                    setActiveMenuButton(0);
                }


                //Case for going from restart button to home button 
                else if (activeMenuButton === 1 && pressedKeyIsRight){
                    menuButtons[1]?.classList?.remove("focused-button");
                    menuButtons[2]?.classList?.add("focused-button");
                    setActiveMenuButton(2);
                } 


                //Case for going from home button to restart button
                else if (activeMenuButton === 2 && pressedKeyIsLeft){
                    menuButtons[2]?.classList?.remove("focused-button");
                    menuButtons[1]?.classList?.add("focused-button");
                    setActiveMenuButton(1);
                }
            }

            //multiplayer menu has 1 button only, so functionality not needed
            else {
                //pass
            }

            return;
        }



        //Handle pressing Enter key while in the menu
        if (isRemoteInMenuButtons && pressedKeyIsEnter){

            //Singleplayer menu
            if (gameState.isAIGame){

                //**TODO**: Fix navigating difficulty menu with keyboard   
                if (activeMenuButton == 0){
                    let difficultyHTMLElement = menuButtons[0];
                    let difficultySelectElement = difficultyHTMLElement.childNodes[0];
                    difficultySelectElement.focus();
                }

                //Restart button
                else if (activeMenuButton == 1){
                    restartGame(setGameState);
                }

                //Home button
                else if (activeMenuButton == 2){
                    handleHome(setGameState, setMultiplayerState);
                }
            }

            //Multiplayer menus
            else if (!gameState.isAIGame){

                //Host menu
                if (multiplayerState.isHost){
                    handleHome(setGameState, setMultiplayerState);
                }

                //Player menu
                else {
                    forfeitGame(multiplayerState, gameState);
                }
            }
            return;
        }




        //board to board keyboard presses
        let nextSquare = "X";

        //Handle Right press
        if (pressedKeyIsRight) {
            nextSquare = getRightSquare(remoteNavigation.remoteOverSquare);
        } 
        
        //Handle Left press
        else if (pressedKeyIsLeft) {
            nextSquare = getLeftSquare(remoteNavigation.remoteOverSquare);
        } 
        
        //Handle Up press
        else if (pressedKeyIsUp) {
            nextSquare = getUpSquare(remoteNavigation.remoteOverSquare);
        } 
        
        //Handle Down press
        else if (pressedKeyIsDown) {
            nextSquare = getDownSquare(remoteNavigation.remoteOverSquare);
        }
        

        if (nextSquare != "X"){
            setHoveredSquare(nextSquare);
            setRemoteNavigation((prev) => ({...prev,remoteOverSquare: nextSquare,}));
        }

        
        //Handle Enter press (on board)
        if (pressedKeyIsEnter) {

            let nextSquare = remoteNavigation.remoteOverSquare;

            //Invalid move
            if (!allowMove() || nextSquare == "X") {
                setRemoteNavigation((prev) => ({...prev,remoteOverSquare: null,}));
                setHoveredSquare(null)
                return;
            }

            //Valid move
            else {
                setRemoteNavigation((prev) => ({...prev, remoteClickSquare: nextSquare,}));
                onSquareClick(nextSquare);
                setHoveredSquare(nextSquare);
                setRemoteNavigation((prev) => ({...prev,remoteOverSquare: nextSquare,}));
            }
        }
    }






    /**
     * Handles the promotion of a pawn.
     * Opens the promotion dialog if the move is a promotion.
     * Changes the pending move to the promotion type to complete the move.
     *
     * @param {String} sourceSquare the source square of the piece.
     * @param {String} targetSquare the target square of the piece.
     */
    function handlePromotion(sourceSquare, targetSquare) {
        if (!availableMoves.includes(targetSquare)) {
            return false; // Not a valid move
        }
        const piece = gameState.game.get(sourceSquare);

        if (calculateIsPromotion(sourceSquare, targetSquare, piece)) {
            /* Open promotion dialog and store the pending move */
            setPromotion({ open: true, type: null });
            setPendingMove({ from: sourceSquare, to: targetSquare, piece });
            return true;
        }
        return false;
    }






    /**
     * Highlights squares for available moves.
     * If the move is a capture, the square is highlighted in red.
     * If the game is in check, the king square is highlighted in red.
     * @returns the styles for the highlighted squares.
     */
    //Probably refactor
    function customSquareStyles() {

        let isMoveAllowed = allowMove();
        let isGameOver = gameState.game.isGameOver();

        if (isMoveAllowed === false || isGameOver === true){ 
            return;
        }

        // Highlight last move
        const highlightStyles = {};
        if (
            gameState.lastMove &&
            gameState.lastMove.from &&
            gameState.lastMove.to
        ) {
            highlightStyles[gameState.lastMove.from] = {
                background: "rgba(255, 255, 0, 0.5)",
            };
            highlightStyles[gameState.lastMove.to] = {
                background: "rgba(255, 255, 0, 0.3)",
            };
        }

        // Highlight the king square with flashing red if in check
        if (gameState.game.inCheck()) {
            const kingSquare = findKingFromFenString(
                gameState.game.fen(),
                gameState.game.turn(),
            );
            highlightStyles[kingSquare] =
                CustomBoardConstants.KING_SQUARE_INCHECK_STYLES;
        }

        // Highlight clicked piece square
        if (clickedPiece) {
            highlightStyles[clickedPiece] = {
                background: `rgba(255, 255, 0, 0.5)`,
            };
        }

        // Highlight remote navigation square
        if (remoteNavigation.remoteOverSquare) {
            highlightStyles[remoteNavigation.remoteOverSquare] = {
                // add an inset border
                boxShadow: "inset 0 0 0 10px rgba(0,100,255,0.7)",
            };
        }

        // Highlight available moves when piece is selected/dragged.
        availableMoves.forEach((move) => {
            const rgba = gameState.game.get(move)
                ? CustomBoardConstants.CAPTURE_MOVE_SQUARE_STYLES // Red for capture moves.
                : CustomBoardConstants.AVAIL_MOVE_SQUARE_STYLES;

            highlightStyles[move] =
                remoteNavigation.remoteOverSquare === move
                    ? {
                          background: `radial-gradient(circle, rgba(0, 255, 0, 0.5) 30%, transparent 50%)`,
                          borderRadius: "50%",
                      }
                    : {
                          background: `radial-gradient(circle, ${rgba})`,
                          borderRadius: "50%",
                      };
            const div = document.querySelector(`[data-square="${move}"]`);
            div?.classList?.add("available-moves");
        });
        return highlightStyles;
    }





    /**
     * Checks if a piece can be dragged.
     * Color must be the same as the player's color and it must be the player's turn.
     * @param {*} piece the piece to check.
     * @returns true if the piece can be dragged, false otherwise.
     */

    //Doesn't really work either way... Refactor?
    function isDraggablePiece(piece) {

        /*
        var isGameOver = gameState.game.isGameOver();
        var correctColorPieceClicked = (piece.piece.charAt(0) === multiplayerState.playerColor);
        var noForfeitOccured = !multiplayerState.forfeit.forfeit;

        if (isGameOver === false && correctColorPieceClicked === true && noForfeitOccured === true){
            return true;
        } else {
            return false;
        }
        */

        return (
            !gameState.game.isGameOver() &&
            piece.piece.charAt(0) === multiplayerState.playerColor &&
            gameState.game.turn() === multiplayerState.playerColor &&
            !multiplayerState.isHost  &&   !multiplayerState.forfeit.forfeit
        );
    }
    


    

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [
        clickedPiece,
        availableMoves,
        remoteNavigation.remoteOverSquare,
        remoteNavigation.remoteClickSquare,
        activeMenuButton,
        isRemoteInMenuButtons,
        promotion.open,
    ]);





    return (
        <div className={`chessboard-wrapper`} data-testid="custom-chessboard">
            <ChessboardDnDProvider>
                <Chessboard
                    id="chessboard"
                    data-testid="chessboard"
                    boardOrientation={`${multiplayerState.playerColor === "w" || multiplayerState.isHost ? "white" : "black"}`}
                    boardWidth={calcBoardWidth()}
                    autoPromoteToQueen={true}
                    onMouseOutSquare={() => setHoveredSquare(null)}
                    onMouseOverSquare={(square) => setHoveredSquare(square)}
                    position={gameState.fen}
                    onPieceDrop={onDrop}
                    isDraggablePiece={isDraggablePiece}
                    onPieceDragBegin={onPieceDragBegin}
                    onPieceDragEnd={onPieceDragEnd}
                    onSquareClick={onSquareClick}
                    promotionDialogVariant="modal"
                    animationDuration={CustomBoardConstants.ANIMATION_DURATION}
                    customSquareStyles={customSquareStyles()}
                    customDarkSquareStyle={{}} // Gets rid of default styles
                    customLightSquareStyle={{}}
                    customBoardStyle={{
                        transform: "perspective(1000px) rotateX(5deg)",
                        filter: "brightness(1.2)",
                        boxShadow: "-30px -10px 8px 0px rgba(0, 0, 0, 0.5)",
                        transformOrigin: "center",
                        borderRadius: "10px",
                        background: 'url("/images/chess-board.webp")',
                        padding: calcBoardPadding(),
                        backgroundPosition: "center",
                        backgroundSize: "100% 100%",
                        position: "relative",
                    }}
                    customPieces={useCustomPieces(
                        hoveredSquare,
                        clickedPiece,
                        onSquareClick,
                        remoteNavigation.remoteClickSquare,
                    )}
                />
            </ChessboardDnDProvider>
        </div>
    );
}