/**
 * multiplayer/handlers.js
 *
 * This file contains the logic for handling WebSocket connections for multiplayer games.
 * It contains functions for handling messages, errors, and game updates.
 * It also contains functions for sending moves to the server.
 *
 * Last modified: 10/07/2024
 */
import { Chess } from "chess.js";
import { getUserId } from "../utils";

/**
 * Handles the logic for when the WS connection tells the player that the game is ready.
 * This function is called when the opponent has joined the game, and is sent to both opponents.
 * @param {React.Dispatch<React.SetStateAction<string>} setGameState used to set the game state.
 * @param {React.Dispatch<React.SetStateAction<string>} setMultiplayerState used to set the player's color.
 * @param {Object} messageData the data from the WS message.
 */
export function handleReady(setGameState, setMultiplayerState, messageData) {
    const prevID = localStorage.getItem("userID");
    const currID = messageData.game.Player1.M.PlayerID.S;
    setMultiplayerState((prev) => ({
        ...prev,
        playerOne: messageData.game.Player1.M.Username.S,
        playerTwo: messageData.game.Player2.M.Username.S,
        playerColor: currID === prevID ? "w" : "b",
    }));
    setGameState((prev) => ({
        ...prev,
        fen: messageData.game.GameState.S,
        id: messageData.game.GameID.S,
        showGame: true,
        game: new Chess(messageData.game.GameState.S),
    }));
}

/**
 * Sends a move to the server.
 * @param {WebSocket} ws the WebSocket connection to the server.
 * @param {String} gameID the game ID to send the move to.
 * @param {String} updatedGameState the updated game state to send.
 */
export function sendMultiplayerGameUpdate(ws, gameID, updatedGameState) {
    const msg = JSON.stringify({
        action: "move",
        gameID: gameID,
        gameState: updatedGameState,
    });
    ws.send(msg);
}

/**
 * Handles an update to the game state.
 * @param {React.Dispatch<React.SetStateAction<string>} setGameState used to update game state.
 * @param {React.Dispatch<React.SetStateAction<Chess>} setGame used to update game reference.
 * @param {Object} messageData the message data from the WS.
 */
export function handleUpdate(setGameState, messageData) {
    setGameState((prev) => ({
        ...prev,
        fen: messageData.game.GameState.S,
        game: new Chess(messageData.game.GameState.S),
    }));
}

/**
 * Handles the logic for when the WS connection closes.
 * Re-connects to the server if the connection is lost.
 * @param {React.Dispatch<React.SetStateAction<string>} setGameState used to set the game state.
 * @param {Boolean} waitingForOpponent true if the player is waiting for an opponent.
 */
export function handleClose(gameState, waitingForOpponent) {
    console.log("WS disconnected", waitingForOpponent);
    localStorage.removeItem(`multiplayer_chess_game_${gameState.id}`);
}

/**
 * Handles a message from the WebSocket connection.
 *
 * This function is called whenever a message is recieved from the server.
 * It will parse the message and handle the game logic.
 * @param {Object} message the message object from the WebSocket connection.
 * @param {React.Dispatch<React.SetStateAction<string>} setGameState used to set the game state.
 * @param {React.Dispatch<React.SetStateAction<string>} setMultiplayerState used to set the player's color.
 */
export function handleMessage(
    message,
    setGameState,
    setMultiplayerState,
    setLoading,
) {
    if (message?.data === "pong") return;

    const data = JSON.parse(message.data);
    if (data === "full_game") {
        /* Show the game is full message */
        setGameState((prev) => ({ ...prev, fullGame: true }));
        return;
    }

    /* Initial message recieved when creating a new game */
    if (data.gameID) {
        localStorage.setItem("gameID", data.gameID);
        setGameState((prev) => ({ ...prev, fen: null, id: data.gameID }));
    }

    /* Game status message, handles game ready and game updates */
    if (data.gameStatus) {
        if (data.gameStatus === "ready") {
            handleReady(setGameState, setMultiplayerState, data);
            setLoading({ isLoading: false, message: null });
        } else if (data.gameStatus === "update") {
            handleUpdate(setGameState, data);
        } else if (data.gameStatus === "forfeit") {
            setMultiplayerState((prev) => ({
                ...prev,
                forfeit: {
                    forfeit: true,
                    winner: data.winner,
                    playerID: data.playerID,
                },
            }));
        } else if (data.gameStatus === "player_joined") {
            setMultiplayerState((prev) => ({
                ...prev,
                playerOne: data.game.Player1.M.Username.S,
                playerTwo: data.game.Player2.M.Username.S,
            }));
        }
    }
}

/**
 * Handles an error in the WebSocket connection.
 * TODO: Show the player or try to reconnect.
 * @param {Error} err
 * @param {WebSocket} ws
 */
export function handleError(err, ws) {
    console.error("WS error", err);
    ws.close();
}

/**
 * Handles the logic for when the player is hosting a game.
 * @param {WebSocket} newWS the WebSocket connection to the server.
 * @param {React.Dispatch<React.SetStateAction<boolean>} setMultiplayerState used to set the multiplayer state.
 */
export function handleHost(newWS, setMultiplayerState) {
    console.log("WS opened");
    const userID = getUserId();
    setMultiplayerState((prev) => ({ ...prev, ws: newWS }));
    localStorage.setItem("userID", userID); // TODO dont need this
    const msg = JSON.stringify({ action: "new" });
    newWS.send(msg);
}

/**
 * Handles joining a game.
 * @param {String} gameID the game ID to join.
 * @param {WebSocket} ws the WebSocket connection to the server.
 */
export function handleJoin(gameID, ws, username) {
    console.log("Joining game with ID: ", gameID);
    console.log("username: ", username);
    const userID = getUserId();
    localStorage.setItem("gameID", gameID);
    ws.send(
        JSON.stringify({
            action: "join",
            gameID: gameID,
            playerID: userID,
            username: username,
        }),
    );
}
