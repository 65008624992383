import { useGame } from "../../game/GameContext";
import "./GameOver.css";

/**
 * GameOver component
 * Handles the game over modal, allows the user to restart the game or go back to the home screen
 * @returns the game over modal
 */
export default function GameOver() {
    const { gameState, multiplayerState } = useGame();

    //Check if the game is over
    var isCheckmateOrStaleMateOrDraw  = gameState.game.isGameOver()
    var isForfeit = multiplayerState.forfeit.forfeit

    //If the game is not over, return
    if (!isCheckmateOrStaleMateOrDraw && !isForfeit) {
        return null;
    }

    // Determine the winner of the game based on the game state
    // If the game is a computer game, the winner is determined by the turn of the game
    let winner, forfeitWinner;
    if (gameState.isAIGame) {
        winner = gameState.game.turn() === "w" ? "Black" : "White";
        forfeitWinner = gameState.game.turn() === "w" ? "Black" : "White";
    } else {
        winner =
            multiplayerState.forfeit.winner === "w"
                ? multiplayerState.playerTwo
                : multiplayerState.playerOne;
        forfeitWinner =
            multiplayerState.forfeit.winner === "w"
                ? multiplayerState.playerOne
                : multiplayerState.playerTwo;
    }

    const result = gameState.game.isCheckmate() ? "Checkmate!" : "Stalemate!";

    return (
        <div className="game-over">
            <h1>{multiplayerState.forfeit.forfeit ? "Forfeit!" : result}</h1>
            <h2>
                {multiplayerState.forfeit.forfeit ? forfeitWinner : winner}{" "}
                wins!
            </h2>
        </div>
    );
}
